<template>
	<Dialog :dialog.sync="documentDialog">
		<template v-slot:title> Upload Document </template>
		<template v-slot:body>
			<v-form
				ref="documentForm"
				v-model.trim="export_valid"
				lazy-validation
				v-on:submit.stop.prevent="submitDocument()"
			>
				<div class="px-2">
					<AutoCompleteInput
						hide-details
						:items="customerList"
						:disabled="attachLoading"
						:loading="attachLoading"
						id="group"
						:rules="[vrules.required(customer, 'Customer')]"
						:class="{ required: !customer }"
						placeholder="Customer"
						v-model="customer"
					></AutoCompleteInput>
				</div>
				<br />
				<table style="table-layout: fixed" class="table-document" width="100%">
					<template v-for="(file, index) in files">
						<tr :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
							<td width="45%" class="px-2">
								<v-file-input
									:id="`document-file-${index}`"
									placeholder="Select File"
									outlined
									class="mt-3"
									prepend-icon=""
									prepend-inner-icon="mdi-attachment"
									hide-details
									v-model="file.file"
									v-on:change="updateFile(index, $event)"
									:rules="[vrules.requiredFile(file.file, `File ${index + 1}`)]"
									:class="{ required: !file.file }"
									v-on:click:clear="updateFile(index, $event)"
								></v-file-input>
							</td>
							<td width="45%" class="px-2">
								<TextInput
									:id="`document-name-${index}`"
									:class="{ required: !file.name }"
									:rules="[vrules.required(file.name, `Name ${index + 1}`)]"
									v-model="file.name"
									hide-details
									placeholder="File Name"
									:suffix="file.suffix"
								></TextInput>
							</td>
							<td width="10%" align="center" valign="middle" class="px-2" rowspan="2">
								<v-btn
									:disabled="files.length < 2"
									v-on:click="removeFile(index)"
									color="red lighten-1 white--text"
									class="mt-3"
									icon
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</td>
						</tr>
						<tr :key="`file-remark-${index}`" :class="{ 'table-alternate-row': index % 2 }">
							<td colspan="2" width="90%" class="px-2 py-3">
								<TextAreaInput
									:rows="2"
									dense
									:id="`document-remark-${index}`"
									hide-details
									v-model="file.remark"
									placeholder="Enter remark..."
									:disabled="attachLoading"
									:loading="attachLoading"
								></TextAreaInput>
							</td>
						</tr>
					</template>
				</table>
				<v-btn color="blue darken-4 white--text" class="mt-2" tile depressed v-on:click="addMore()">
					<v-icon>mdi-plus</v-icon> Add More...
				</v-btn>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				:disabled="!export_valid || attachLoading"
				:loading="attachLoading"
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				v-on:click="submitDocument()"
			>
				Submit
			</v-btn>
			<v-btn
				depressed
				tile
				:disabled="attachLoading"
				:loading="attachLoading"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog.vue";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";

export default {
	name: "export-dialog",
	data() {
		return {
			export_valid: true,
			attachLoading: false,
			files: [
				{
					file: null,
					name: null,
					remark: null,
					suffix: null,
				},
			],
			customerList: [],
			customer: null,
		};
	},
	props: {
		documentDialog: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		documentDialog(param) {
			if (param) {
				this.customer = null;
				this.files = [
					{
						file: null,
						name: null,
						remark: null,
						suffix: null,
					},
				];
			}
		},
	},
	methods: {
		addMore() {
			this.files.push({
				file: null,
				name: null,
				remark: null,
				suffix: null,
			});
		},
		submitDocument() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.documentForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.documentForm.validate()) {
				return false;
			}

			const params = new FormData();

			for (let i = 0; i < this.files.length; i++) {
				params.append(`file[${i}][file]`, this.files[i].file);
				params.append(`file[${i}][name]`, this.files[i].name);
				params.append(`file[${i}][remark]`, this.files[i].remark);
			}

			params.append("customer", this.customer);

			this.attachLoading = true;

			ApiService.setHeader();
			ApiService.upload("documents", params)
				.then(() => {
					this.$emit("success", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.attachLoading = false;
				});
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
	},
	components: {
		Dialog,
		TextInput,
		AutoCompleteInput,
		TextAreaInput,
	},
	mounted() {
		this.customerList = this.localDB("customers", []);
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
};
</script>
